// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://localhost:4200',
  recaptcha: {
  siteKey: '6Ld78tQjAAAAANXmZ9GWuQ50JzAwRXlQFHVy2HAp',
  },
    firebase:  {
  apiKey: "AIzaSyDftx6Kp4L43NICo4ltBE1qPNUt0e_x_Z0",
  authDomain: "ashphalt-991.firebaseapp.com",
  databaseURL: "https://ashphalt-991-default-rtdb.firebaseio.com",
  projectId: "ashphalt-991",
  storageBucket: "ashphalt-991.appspot.com",
   appId: "1:149361192840:web:14a4f1dbed1cd1e908543d"
  }

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
