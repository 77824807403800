import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { GuestLayoutComponent } from './layout/app-layout/guest-layout/guest-layout.component';

const routes: Routes = [


 {
    path: '',
    component: GuestLayoutComponent,
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },

      {
        path: '',
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then((m) => m.ExtraPagesModule),
      },
  ]
  },

  //     { 
  //     path: '', 
  //     component: GuestLayoutComponent,
  //            loadChildren: () =>
  //         import('./extra-pages/extra-pages.module').then(
  //           (m) => m.ExtraPagesModule
  //         ),
  //   children: [
  //     { path: '', redirectTo: '/home', pathMatch: 'full' },
  
  // ],
  //     // data: {
  //     // guest: true,
  //     // },
  //     // loadChildren: () =>
  //     // import("./pages/pages.module").then(
  //     //   (m) => m.PagesModule
  //     //   ),
  //   },


  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      // {
      //   path: 'admin',
      //   canActivate: [AuthGuard],
      //   data: {
      //     role: Role.Admin,
      //   },
      //   loadChildren: () =>
      //     import('./admin/admin.module').then((m) => m.AdminModule),
      // },
      // {
      //   path: 'doctor',
      //   canActivate: [AuthGuard],
      //   data: {
      //     role: Role.Doctor,
      //   },
      //   loadChildren: () =>
      //     import('./doctor/doctor.module').then((m) => m.DoctorModule),
      // },
      {
        path: 'patient',
        canActivate: [AuthGuard],
        data: {
          role: Role.Patient,
        },
        loadChildren: () =>
          import('./patient/patient.module').then((m) => m.PatientModule),
      },
      {
        path: 'extra-pages',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: 'multilevel',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./multilevel/multilevel.module').then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
