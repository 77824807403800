import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, Subject, throttleTime, map, filter, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
   public languages: string[] = ["en", "fr"];

  localChange: Subject<string> = new Subject<string>();
  public browserLang;

  constructor(public translate: TranslateService) {
     translate.addLangs(this.languages);
this.browserLang = 'fr';
    // if (localStorage.getItem('lang')) {
    //   this.browserLang = localStorage.getItem('lang');
    // } else {
    //   this.browserLang = translate.getBrowserLang();
    // }
    translate.use(this.browserLang.match(/en|es|fr/) ? this.browserLang : 'fr');
  }

  public setLanguage(lang) {
    this.browserLang = lang;
    this.localChange.next(lang)
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }
}
