import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { DirectionService } from "src/app/core/service/direction.service";
import $ from 'jquery';
import { DynamicScriptLoaderService } from "src/app/core/service/dynamic-script-loader.service";
import { LanguageService } from "src/app/core/service/language.service";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import * as M from "materialize-css/dist/js/materialize";


@Component({
  selector: "app-guest-layout",
  templateUrl: "./guest-layout.component.html",
  styleUrls: ["./guest-layout.component.scss"],
    animations: [
  trigger('mode', [ transition('wb_sunny <=> brightness_2', [ 
    style({ transform: `scale(1.5)`, opacity: 0 }), animate('.2s 0s ease-out'),
    ])
  ]),
  trigger('lang', [ transition('en <=> fr', [ 
    style({ transform: `scale(1.5)`, opacity: 0 }), animate('.2s 0s ease-out'),
    ])
  ]),
  ]
})
export class GuestLayoutComponent implements OnInit {
  direction: string;
  public config: any = {};
  constructor(
    public router : Router,
    private languageService : LanguageService, 
    private dynamicScriptLoaderService : DynamicScriptLoaderService, private directoryService: DirectionService) {
        // this.dynamicScriptLoaderService.loadInFooter("jquery", "bootstrap", "enquire", "touchSwipe", "magnific", "enllax", "parallax", "youtubebackground", "form", "slick", "wow", "navScroll", "modernizr", "materialize", "scripts");

    // this.directoryService.currentData.subscribe((currentData) => {
    //   if (currentData) {
    //     this.direction = currentData;
    //   } else {
    //     if (localStorage.getItem("isRtl")) {
    //       if (localStorage.getItem("isRtl") === "true") {
    //         this.direction = "rtl";
    //       } else if (localStorage.getItem("isRtl") === "false") {
    //         this.direction = "ltr";
    //       }
    //     } else {
    //       if (this.config.layout.rtl == true) {
    //         this.direction = "rtl";
    //       } else {
    //         this.direction = "ltr";
    //       }
    //     }
    //   }
    // });
  }
  ngOnInit(): void {

//     document.addEventListener('DOMContentLoaded', function() {
    
//     // var instanceDropdown1 = M.Dropdown.init(dropdown1, dropdownOptions);
// });
  

  }

  scroll(el) {
    var tmp : HTMLElement = document.getElementById(el) as HTMLElement;
    tmp.scrollIntoView();
}

  back(){
    window.location.href = "/home"
  }


  get lang(): 'en' | 'fr' {
    // return this.state ? 'en' : 'fr';
    return this.languageService.browserLang || 'en'
  }
setLanguage(lang: string) {
  var target = (lang !== "en") ? "en" : "fr"
  this.languageService.setLanguage(target);
}
}
