import { Injectable } from '@angular/core';
interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
 {
    name: 'font-awesome',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/js/all.min.js'
  },
  {
    name: 'font-brand',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/js/brands.min.js'
  },
  {
    name: 'jquery',
    src: '../../assets/guest/js/vendors/jquery.min.js'
  },
  {
    name: 'bootstrap',
    src: '../../assets/guest/js/vendors/bootstrap.min.js'
  },
  {
    name: 'enquire',
    src: '../../assets/guest/js/vendors/enquire.min.js'
  },
  {
    name: 'enllax',
    src: '../../assets/guest/js/vendors/jquery.enllax.min.js'
  },
  {
    name: 'form',
    src: '../../assets/guest/js/vendors/jquery.form-validator.min.js'
  },
  {
    name: 'touchSwipe',
    src: '../../assets/guest/js/vendors/jquery.touchSwipe.min.js'
  },
  {
    name: 'youtubebackground',
    src: '../../assets/guest/js/vendors/jquery.youtubebackground.js'
  },

    {
    name: 'magnific',
    src: '../../assets/guest/js/vendors/jquery.magnific-popup.min.js'
  },
  {
    name: 'pace',
    src: '../../assets/guest/js/vendors/pace.min.js'
  },
    {
    name: 'parallax',
    src: '../../assets/guest/js/vendors/parallax.min.js'
  },
  {
    name: 'slick',
    src: '../../assets/guest/js/vendors/slick.min.js'
  },
  {
    name: 'wow',
    src: '../../assets/guest/js/vendors/wow.min.js'
  },
  {
    name: 'navScroll',
    src: '../../assets/guest/js/vendors/jquery.navScroll.min.js'
  },
  {
    name: 'modernizr',
    src: '../../assets/guest/js/vendors/modernizr-2.8.3-respond-1.4.2.min.js'
  },
  {
    name: 'materialize',
    src: '../../assets/guest/js/vendors/materialize.js'
  },
  {
    name: 'scripts',
    src: '../../assets/guest/js/scripts.js'
  },
  {
    name: 'particles',
    src: '../../assets/guest/js/vendors/particles/particles-spray.js'
  },
  {
    name: 'exapandable',
    src: '../../assets/lib/expandable.js'
  }

  ];
declare var document: any;
@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService {
  private scripts: any = {};
  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }
  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

    loadInFooter(...scripts: string[]) {
    const promises: any[] = [];
    // load each script from parameters
    scripts.forEach((script) => promises.push(this.loadScriptInFooter(script)));
    // return and resolve all promises from scripts
    return Promise.all(promises);
  }

  loadScriptInFooter(name: string) {
    return new Promise((resolve, reject) => {
      // check if script is loaded. if not load script
      if (!this.scripts[name].loaded) {

        //load script
        // create script
        let script = document.createElement('script');
        // script type
        script.type = 'text/javascript';
        script.id = name;
        // script src is the src from our scripts obj
        script.src = this.scripts[name].src;

        // if (script.readyState) {
        //   //Only for internet explorer
        //   // load script and resolve promise
        //   script.onreadystatechange = () => {
        //     if (script.readyState === "loaded" || script.readyState === "complete") {
        //       script.onreadystatechange = null;
        //       this.scripts[name].loaded = true;
        //       resolve({
        //         script: name,
        //         loaded: true,
        //         status: 'Loaded'
        //       });
        //     }
        //   };
        // } else { //Others
        //   // load script and resolve promise
        //   script.onload = () => {
        //     this.scripts[name].loaded = true;
        //     resolve({
        //       script: name,
        //       loaded: true,
        //       status: 'Loaded'
        //     });
        //   };
        // }
        // set on error handler function. resolve the promise with not loaded is false
        script.onerror = (error: any) => resolve({
          script: name,
          loaded: false,
          status: 'Loaded'
        });
        // add this script to the header

        document.getElementsByTagName('body')[0].appendChild(script);
                resolve({
              script: name,
              loaded: true,
              status: 'Loaded'
        });

      } else {
        // if the script is already loaded then resolve the promise
        resolve({
          script: name,
          loaded: true,
          status: 'Already Loaded'
        });
      }
    });
  }

}
