<div class="m-application theme--dark transition-page" id="app">
  <div class="loading"></div>
  <div class="m-content ocean-blue ocean-blue-var" id="main-wrap">
    <div>
      <div *ngIf="router.url === 'home'" id="home"></div>
      <div class="main-wrap">
        <div class="sidenav mobile-nav" id="slide-menu" *ngIf="router.url === 'home'">
          <ul class="collection">
            <li class="collection-item" style="animation-duration: 0.25s"><a class="sidenav-close waves-effect menu-list" href="/home">acceuil</a></li>
            <li class="collection-item" style="animation-duration: 0.5s"><a class="sidenav-close waves-effect menu-list" href="#services">services</a></li>
            <!-- <li class="collection-item" style="animation-duration: 0.75s"><a class="sidenav-close waves-effect menu-list" href="#about">about</a></li> -->
            <li class="collection-item" style="animation-duration: 1s"><a class="sidenav-close waves-effect menu-list" href="#case-studies">{{'Projets' | translate}}</a></li>
            <li class="collection-item" style="animation-duration: 1s"><a class="waves-effect menu-list" href="/contact">contact</a></li>
          </ul>
        </div>
        <div class="info" style="" align="right">
          <div class="  " style="display:none;     flex-direction: row-reverse; 
 align-content: space-between; align-items: center; padding: 5px;" align="right">
            <!--     <h6 class="d-flex ml-5 mr-5">
                <a style="color:white;" href="mailto:asphalte.metropolitain@gmail.com">
                <span style="font-size: 15px;"><i style="font-size: 15px; color:red" class="material-icons ">email</i>&nbsp;
                  <u>asphalte.metropolitain@gmail.com</u></span></a>
                </h6>  -->
            <!--       <h6 class="d-flex "><span style="font-size: 15px;"><i style="font-size: 15px; color:red" class="material-icons  ">phone</i>
                  +1 514-895-7325</span></h6> -->
          </div>
        </div>
        <header class="app-bar header" id="header">
          <div class="container">
            <!--         <div class="header-top">
    <div class="user-menu">
        <div class="hidden-sm-down"><i class="material-icons me-2">phone</i>+12 345 678 90
            <hr class="divider"><a class="btn btn-flat waves-effect" href="login.html">log in</a> <a class="btn secondary button waves-effect" href="register.html">register</a></div>
        
    </div>
</div> -->
            <!--   <div class="header-top w-100 padding-0 margin-0">
    <div class="user-menu">
        <div class="hidden-sm-down"><i class="material-icons me-2">phone</i>+12 345 678 90
            <hr class="divider">
           
          </div>
        
    </div>
</div> -->
            <div class="header-content">
              <nav class="nav-logo   flex-row flex-fill justify-content-between">
                <div>
                  <button *ngIf="router.url === '/home'" class="mobile-menu btn-icon waves-effect hamburger hamburger--spin show-sm-down" id="mobile_menu" type="button">
                    <span class="hamburger-box"><span class="bar hamburger-inner"></span></span>
                  </button>
                  <button *ngIf="router.url !== '/home'" (click)="back()" class="mobile-menu btn-icon waves-effect hamburger hamburger--spin show-sm-down" id="mobile_menu" type="button">
                    <i class="fa fa-2x fa-arrow-left"></i>
                  </button>
                </div>
                <div class="logo scrollnav" style="  ">
                  <a href="#home" style="font-size:18px">
                    <img src="assets/logo.png" style="min-width:7em !important; height:100% !important; filter: brightness(2) grayscale(10%); " alt="logo" />
                  </a>
                </div>
                <div class="px-1">
                  <a href="tel:+15148957325" class="mobile-menu btn-icon waves-effect   show-sm-down float-right" id="mobile_menu" type="button">
                    <i class="fa fa-2x fa-phone"></i>
                  </a>
                </div>
              </nav>
              <nav class="nav-menu">
                <div class="scrollactive-nav show-md-up scrollnav">
                  <ul>
                    <li><a class="btn btn-flat anchor-link waves-effect" href="#home">{{'home' | translate }}</a></li>
                    <li><a class="btn btn-flat anchor-link waves-effect" href="#about">{{'about' | translate }}</a></li>
                    <li>
                      <!-- <a class="btn btn-flat dropdown-trigger anchor-link waves-effect"   data-target="dropdown_config" >{{'services' | translate }}</a>  -->
                      <a class='dropdown-button dropdown-trigger btn' data-activates='dropdown1' data-target="dropdown1">{{'services' | translate }} <i class="material-icons right">arrow_drop_down</i>
                      </a>
                      <!-- Dropdown Structure -->
                      <ul id='dropdown1' class='dropdown-content'>
                        <li><a (click)="scroll('services_carousel')">Asphalte</a>
                        </li>
                        <li><a href="#!">Pavé-unis</a></li>
                        <li class=""><a href="#!">Drain français</a></li>
                        <li class=""><a href="#!">Murets</a></li>
                        <li class=""><a href="#!">Excavation</a></li>
                      </ul>
                    </li>
                    <!-- <li><a class="btn btn-flat anchor-link waves-effect" href="#our-expertise">our expertise</a></li> -->
                    <li><a class="btn btn-flat anchor-link waves-effect" href="#case-studies">{{'projects' | translate }}</a></li>
                    <li><a class="btn btn-flat anchor-link waves-effect" href="/contact">contact</a></li>
                  </ul>
                </div>
                <div class="menu-setting">
                  <div class="setting">
                    <!-- <button class="btn btn-outlined waves-effect " href="">&nbsp;FR<small>|</small>EN&nbsp; </button> -->
                    <!--  <button (click)="setLanguage(lang)" [@lang]="lang" [ngClass]="(lang)" id="lang" class="btn btn-icon social-btn waves-effect language" data-align="left">
              <i class="icon" ></i>FR
            </button> -->
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <router-outlet></router-outlet>
      </div><!-- ##### PAGE NAV #####-->
      <div class="hidden-md-down" *ngIf="router.url === '/home'">
        <div class="page-nav" id="page_nav">
          <nav class="section-nav">
            <div class="scrollnav">
              <ul>
                <!--  <li style="top: 120px"><a class="tooltipped" href="#about" data-position="left" data-tooltip="about"></a></li>
                <li style="top: 90px"><a class="tooltipped" href="#services" data-position="left" data-tooltip="services"></a></li>
                <li style="top: 60px"><a class="tooltipped" href="#our-expertise" data-position="left" data-tooltip="our expertise"></a></li>
                <li style="top: 30px"><a class="tooltipped" href="#case-studies" data-position="left" data-tooltip="case studies"></a></li> -->
              </ul>
            </div>
          </nav>
          <div class="scrollnav"><a class="btn-floating btn-large primary tooltipped waves-effect waves-light" href="#home" data-position="left" data-tooltip="To Top">
              <div class="icon material-icons">arrow_upward</div>
            </a></div>
        </div>
      </div><!-- ##### END PAGE NAV #####-->
      <!-- ##### NOTIFIACTION #####-->
      <div class="hidden-md-down invisible" *ngIf="router.url === '/home'">
        <div class="alert full alert-dismissible fade show notification" role="alert">
          <div class="wrapper">
            <div class="content">
              <div class="action">This is an example of a message that is commonly used for important information to visitors or users. Please click the button beside to close this message.</div>
              <button class="btn waves-effect white" type="button" data-dismiss="alert" aria-label="Close">Accept</button>
            </div>
          </div>
        </div>
      </div><!-- ##### END NOTIFIACTION #####-->
      <!-- ##### FOOTER #####-->
      <footer class="container footer">
        <div class="row">
          <div class="col-sm-12 col-lg-12  mb-2 px-0">
            <div class="card paper" style="border: 2px solid var(--v-secondary-base);">
              <div class="d-block   justify-content-center p-2 " align="center">
                <h6 class="title">Contact</h6>
                <div class="col-sm-12   mt-2 mb-2 ">
                  <h6 style="color:red; font-size:100%;"><b>Email</b></h6>
                  <p class="" onauxclick="" style="
    white-space: nowrap;
"><a href="mailto:asphalte.metropolitain@gmail.com" style="color: white;">asphalte.metropolitain@gmail.com </a></p>
                </div>
                <div class="col-sm-12   mt-2 mb-2 ">
                  <h6 style="color:red; font-size:100%;"><b>Téléphone</b></h6>
                  <p><a href="tel:+15148957325"  style="color: white;">+1 514 895 7325</a></p>
                </div>
                <div class="col-sm-12   mt-2 mb-2 ">
                  <h6 style="color:red; font-size:100%;"><b>Adresse</b></h6>
                  <p> Montreal,QC Canada</p>
                </div>
                <div class="col-sm-12   mt-2 mb-2 ">
                  <h6 style="color:red; font-size:100%;"><b>Horaire d'ouverture</b></h6>
                  <p class="mb-0"> Lundi - Samedi:</p>
                  <p> 8h00 - 18h00</p>
                </div>
                <img _ngcontent-ilg-c195="" src="assets/logo.png" alt="logo" style=" width: 140px !important; height: auto !important; filter: brightness(2) grayscale(10%);" class="ng-tns-c195-0">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-4 px-0" align="center">
            <!--  <div class="logo">
                <img src="./assets/images/agency-logo.png" alt="logo" />
                <h6 class="title">Agency Project</h6>
              </div> -->
            <!-- <p class="footer-desc pb-2">We have been in business for almost a decade. Helping you is our top priority.</p> -->
            <p class="body-2 show-md-up">&copy; Asphalte Metropolitain - 2023</p>
          </div>
          <div class="col-12 pa-1">
            <div class="row justify-content-evenly">
              <div class="socmed">
                <a class="btn btn-icon waves-effect" href="https://www.facebook.com/entretienasphaltemetropolitain/"><span class="ion-social-facebook icon"></span></a>
                <a class="btn btn-icon waves-effect" href="https://www.instagram.com/entretienasphaltemetropolitain/?hl=en"><span class="ion-social-instagram icon"></span></a>
                <a class="btn btn-icon waves-effect"><span class="ion-social-linkedin icon"></span></a>
              </div>
            </div>
            <p class="body-2 text-center show-sm-down">&copy; Asphalte Metropolitain - 2023</p>
          </div>
          <div class="col-md-3 pa-4">
          </div>
        </div>
      </footer><!-- ##### END FOOTER #####-->
    </div>
  </div>
</div>
